import gql from 'graphql-tag';

export const organization_settings_vars = `{
    is_segment_on
    segment_key
    is_shake_on
    shake_client_id
    shake_client_secret
    is_api_settings_on
    is_library_nav_on
    is_rewards_nav_on
    is_app_env_on
    is_login_settings_on
    is_testers_on
    is_channel_advanced_on
    is_subscription_on
    is_sales_button_on
    is_group_color_settings_on
    is_group_radius_settings_on
    is_card_back_settings_on
    is_card_back_filters_on
    card_back_hidden_modules
    hidden_home_components
    is_manage_organization_options_settings
    is_super_admin
  }
`

export const archiveAppUserFromOrganizationMutation = gql`
  mutation archiveAppUserFromOrganization($organization_id: ID!, $userId: ID!, $archived: Boolean, $isPermanent: Boolean) {
    archiveAppUserFromOrganization(organization_id: $organization_id, userId: $userId, archived: $archived, isPermanent: $isPermanent)
  }
`;

export const getJWPlayerData = organization_id => gql`
  query jwplayers {
    jwplayers_org(
      organization_id: "${organization_id}"
    ) {
      api_version
      app_key
      app_secret
      id
    }
  }
`;
export const getFilestackData = organization_id => gql`
  query filestacks_org {
    filestacks_org(
      organization_id: "${organization_id}"
    ) {
      api_key
      app_secret
      id
    }
  }
`;
export const getCloudinarySettings = organization_id => gql`
  query cloudinaries {
    cloudinaries_org(
      organization_id: "${organization_id}"
    ) {
      cloud_name
      api_key
      api_secret
      upload_preset
      default_upload_path
      id
    }
  }
`;

export const archiveAdminFromOrganizationMutation = gql`
  mutation archiveAdminFromOrganization($organization_id: ID!, $userId: ID!, $archived: Boolean, $isPermanent: Boolean) {
    archiveAdminFromOrganization(organization_id: $organization_id, userId: $userId, archived: $archived, isPermanent: $isPermanent)
  }
`;

export const sendInviteMutation = gql`
  mutation sendInviteMutation($organization_id: ID!, $userId: ID!, $domain: String!, $isAdmin: Boolean) {
    sendInvite(organization_id: $organization_id, userId: $userId, domain: $domain, isAdmin: $isAdmin)
  }
`;

export const getActivationLink = gql`
  query getUserActivationUrl($organization_id: ID!, $user_id: ID!, $isAdmin: Boolean!, $domain: String!) {
    getUserActivationUrl(organization_id: $organization_id, user_id: $user_id, isAdmin: $isAdmin, domain: $domain)
  }
`

export const forgotPasswordMutation = gql`
  mutation forgotPassword($email: String! $domain: String $isAppUser: Boolean) {
    forgotPassword(email: $email domain: $domain isAppUser: $isAppUser) {
      status
      error
      data
    }
  }
`;

export const createUserWithChannels = gql`
  mutation createUserWithChannels($userAndChannels: UserAndChannels) {
    createUserWithChannels(userAndChannels: $userAndChannels) {
      id
      name
      email
      channels {
        id
        name
      }
    }
  }
`;

export const updateUserWithChannels = gql`
mutation updateUserWithChannels($id: ID!, $userAndChannels: UserAndChannels) {
  updateUserWithChannels(id:$id, userAndChannels: $userAndChannels) {
    id
    name
    email
  }
}
`;

export const createPwaTemplate = gql`
mutation createPwaTemplate($input: PwaTemplateInput) {
  createPwaTemplate(input:$input) {
    id
    display
  }
}
`;

export const updatePwaTemplate = gql`
mutation updatePwaTemplate($id: ID!, $input: PwaTemplateInput) {
  updatePwaTemplate(id: $id, input:$input) {
    id
    display
  }
}
`;

export const updateAdminWithChannels = gql`
mutation updateAdminWithChannels($id: ID!, $userAndChannels: UserAndChannels) {
  updateAdminWithChannels(id:$id, userAndChannels: $userAndChannels) {
    id
    name
    email
  }
}
`;

export const createAdminWithChannels = gql`
  mutation createAdminWithChannels($userAndChannels: UserAndChannels) {
    createAdminWithChannels(userAndChannels: $userAndChannels) {
      id
      name
      email
    }
  }
`;

export const createBulkUser = gql`
mutation createBulkUser ($bulkUserInput: BulkUserInput ){
  createBulkUser(bulkUserInput : $bulkUserInput ){
    status
    error
    data
  }
}
`;

export const ContactDocumentUpload = gql`
  mutation ContactDocumentUpload($csvFile: Upload, $organization_id: String!) {
    ContactDocumentUpload(csvFile: $csvFile, organization_id: $organization_id)
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export const deleteAdmin = gql`
  mutation deleteAdmin($id: ID!) {
    deleteAdmin(id: $id)
  }
`;

export const createOrganization = gql`
  mutation createOrganization(
    $name: String!
    $logo_data: Upload
    $domain: String!
    $admin: AdminAndChannels!
    $auth0: Auth0CredInput!
  ) {
    createOrganizationV1(
      name: $name
      logo_data: $logo_data
      domain: $domain
      admin: $admin
      auth0: $auth0
    ) {
      name
      logo_data
      id
    }
  }
`;

export const updateOrganization = gql`
  mutation updateOrganization(
    $id: ID!
    $name: String!
    $logo_data: Upload
    $domain: String!
    $admin: AdminAndChannels!
    $auth0: Auth0CredInput!
  ) {
    updateOrganizationV1(
      id: $id
      name: $name
      logo_data: $logo_data
      domain: $domain
      admin: $admin
      auth0: $auth0
    ) {
      name
      logo_data
      id
    }
  }
`;

export const deleteOrganization = gql`
  mutation deleteOrganization(
    $id: ID!
  ) {
    deleteOrganizationV1(
      id: $id
    )
  }
`;

export const organization = (orgId, data) => gql`
  query organization{
    organization(id:"${orgId}") {
      name
      admin_invitation_subject
      admin_invitation_copy
      app_user_invitation_copy
      app_user_invitation_subject
      admin_email_welcome_subject
      admin_email_welcome_template
      logo_data
      color_palette
      id
      web_portal_url
      is_locations_available_for_signup
      ios_app_url
      android_app_url
      specifications_filter_query_rule
      tags_filter_query_rule
      chat_users_source
      validate_crm_on_login
      filters_ordering
      privacy_url
      terms_url
      video_cover_photo_data
      dark_logo_data
      requires_biometric_auth
      investment_form_amounts
      auto_create_cardware_crm_user
      crm_user_points
      languages
      invite_email_background_color
      mail_sender_config_from_email
      mail_sender_config_from_name  
      domain{
        domain
      }
      auth0{
        domain
        client_id
        client_secret
        id
      }
      zooms {
        nickname
        id
      }
      googleSheets {
        id
        name
      }
      analytic_integration {
        schema_name
      }
      subscriptions {
        name
        id
      }
      microsoftSheets {
        id
        name
      }
      microsoftSharePointSheets {
        id
        name
      }
      sheets {
        id
        name
      }
      mongo {
        id
        name
      }
      email_template
      admin_activation_email_subject
      admin_activation_email_body
      app_user_activation_email_subject
      app_user_activation_email_body
      admin_invitation_email_bcc
      admin_invitation_email_attach_url
      admin_invitation_email_attach_button_text
      app_user_invitation_email_bcc
      app_user_invitation_email_attach_url
      app_user_invitation_email_attach_button_text
      admin_activation_email_bcc
      admin_activation_email_attach_url
      admin_activation_email_attach_button_text
      app_user_activation_email_bcc
      app_user_activation_email_attach_url
      app_user_activation_email_attach_button_text
      confirmation_text
      hasNextGenVersionEnabled
    }
    organization_settings ${organization_settings_vars}
  }
`;

export const organizations = () => gql`
  query organizations {
    organizations {
      name
      logo_data
      video_cover_photo_data
      id
    }
  }
`;

export const pwa_manifest_templates = () => gql`
  query pwa_manifest_templates {
    pwa_manifest_templates {
      id
      background_color
      organization_id
      display
      start_url
      name
      description
      short_name
      icon
      icon
      scope
      created_at
    }
  }
`;
export const libraries = (organization_id) => gql`
  query libraries {
    libraries_by_org(organization_id: "${organization_id}") {
      id
      title
      description
      type
      library
      tag_ids
      cover_image
      organization_id
      is_cloudinary_rejected
      cloudinary_path
    }
  }
`;
export const filestackLibraries = (organization_id) => gql`
  query libraries {
    filestack_libraries_org(organization_id: "${organization_id}") {
      id
      url
      type
      metadata
      organization_id
      created_at
    }
  }
`;

export const organizationsByEmail = email => gql`
  query organizationsByEmail {
    organization_domains_by_email(email: "${email}"){
      domain
      organization {
        name
        id 
        logo_data
        video_cover_photo_data
      }
    }
  }
`;

export const auth0_users = () => gql`
  query auth0_users {
    auth0_users {
      email
      name
      picture
      user_id
    }
  }
`;

export const app_users = (organization_id) => gql`
  query app_users {
    app_users_org(organization_id: "${organization_id}") {
      id
      email
      name
    }
  }
`;

export const get_assigned_users_to_trainer = (trainer_id) => {
  return gql`
    query fetch_trainer_assigned_users {
      fetch_trainer_assigned_users(
        trainer_id: "${trainer_id}"
      ) {
        id
        app_user_id
      }
    }
  `;
}

export const assign_users_to_trainer = () => {
  return gql`
    mutation assign_user_to_trainer(
      $organization_id: ID
      $trainer_id: ID
      $users: [ID]
    ) {
      assign_user_to_trainer(organization_id: $organization_id trainer_id: $trainer_id users: $users)
    }
  `
}

export const app_users_archived = (organization_id) => gql`
  query app_users_archived {
    app_users_org_archived(organization_id: "${organization_id}") {
      id
      email
      name
      approved
      first_name
      last_name
      job_title
      created_at
      updated_at
      has_all_channels_permission
      is_converted_to_cardware
      channels {
        id
        name
      }
    }
  }
`;

export const admins = (organization_id) => gql`
  query admins {
    admins_org(organization_id: "${organization_id}") {
      id
      email
      name
      approved
      image
      first_name
      last_name
      job_title
      created_at
      updated_at
      has_all_channels_permission
      channels {
        id
        name
        organization_id
      }
    }
  }
`;

export const adminsArchived = (organization_id) => gql`
  query admins_archived {
    admins_org_archived(organization_id: "${organization_id}") {
      id
      email
      name
      approved
      image
      first_name
      last_name
      job_title
      created_at
      updated_at
      has_all_channels_permission
      channels {
        id
        name
      }
    }
  }
`;

export const app_content_scope_groups_org = orgId => gql`
  query app_content_scope_groups_org {
    app_content_scope_groups_org(
      organization_id: "${orgId}"
    ) {
      name
      organization_id
      position
      public
      id
      isNew
      archived
      primary_color
      secondary_color
      deck_cover_radius_top_left
      deck_cover_radius_top_right
      deck_cover_radius_bottom_left
      deck_cover_radius_bottom_right
      deck_shadow_radius_top_left
      deck_shadow_radius_top_right
      deck_shadow_radius_bottom_left
      deck_shadow_radius_bottom_right
      group_navigation_level
      is_files_magic_group
      tags {
        id
        tag_id
      }
    }
  }
`;

export const app_content_scopes_org = orgId => gql`
query app_content_scopes_org{
  app_content_scopes_org(organization_id:"${orgId}"){
    id
    position
    name
    public
    published
    organization_id
    app_content_scope_group_id
    logo_data
    icon_data
    isNew
    pincode
    description
    content_controlled
    media_url
    abbr
    sales_button_enabled
    sales_incentive_button_title
    sales_incentive_description
    sales_incentive_title
    sales_incentive_image
    archived
    is_requires_subscription
    subscription_into_headline
    subscription_description
    subscription_button_text
    subscription_image_data
    subscription_id
    subscription_ids
    subscription {
      name
      id
    }
    is_travel_pass_channel
    has_data_source_connected
    created_at
    can_share_channel_data
    is_file_explorer
    file_explorer_root_path
    file_explorer_default_view
    is_firebase_channel
    is_primary_channel
    is_modal_presentation
    hide_from_navigation
    display_modal_on_no_sub
    display_modal_on_no_acct
    hide_from_navigation_list_on_modal_check
    tags {
      id
      tag_id
      channel_id
      tag {
        name
        id
        cover_image
        type
      }
    }
    modal_presentation_style
    display_modal_on_sub_no_acct
    display_modal_link_acct
    is_multimedia_channel
    is_map_channel
    is_analytics_report_template
  }
}
`;

export const category_app_content_scopes = orgId => gql`
query category_app_content_scopes{
  category_app_content_scopes(organization_id:"${orgId}"){
    id
    category_id
    position
    published
    app_content_scope_id
    isNew
    category {
      name
      archived
      published
      position
      public
      created_by_id
      deck_count
      id
      organization_id
      description
      created_at
      layout
      has_data_source_connected
      api_path_id
      deck_style
      category_tags {
        tag_id
        category_id
        tag {
          id
          name
        }
      }
    }
  }
}
`;

export const deck_categories = (orgId, category_id) => gql`
query deck_categories($category_ids: [ID]){
  deck_categories(
    organization_id:"${orgId}"
    category_ids: $category_ids
    ){
    category_id
    home_component_id
    deck_id
    deck_type
    position
    published
    isNew
    id
    deck_category_deck {
      title
      created_by_id
      cover_type
      cover_image_data
      icon_name
      id
      archived
      organization_id
      description
      card_count
      full_bleed
      home_featured
      organization_color_palette_index
      has_data_source_connected
      api_path_id
      show_answer_validation
      card_style
      use_card_template
      card_template
    }
  }
}
`;

export const card_decks = orgId => gql`
  query card_decks {
    card_decks(organization_id: "${orgId}") {
      deck_id
      card_id
      position
      published
      isNew
      id
      card {
        id
        title
        sub_title
        card_type
        file_data
        front_image_data
        archived
        full_bleed
      }
    }
  }
`;

export const quiz_question_quiz_decks_org = orgId => gql`
  query quiz_question_quiz_decks_org($deck_ids: [ID]) {
    quiz_question_quiz_decks_by_deck_ids(organization_id: "${orgId}", deck_ids: $deck_ids){
    id
    quiz_deck_id
    quiz_question_id
    published
    position
    quiz_question_type
    default_title
    quiz_question_data{
      __typename
      ...on QuizMatchingQuestion {
        question_text
        pairs
        archived
      }
      ...on QuizTextQuestion {
        question_text
        valid_text_responses
        archived
      }
      ...on QuizOptionQuestion {
        question_text
        options
        archived
      }
      ...on DefaultQuizQuestion {
        archived
      }
    }
  }
}
`;


export const quizzes_responses = (organization_id, page) => {
  return gql`
    query quizzes_by_org_paginated {
      quizzes_by_org_paginated (
        organization_id: "${organization_id}"
        page: ${page}
      ) {
        rows {
        id
        quiz_deck_id
        app_user_id
        updated_at
        deck {
          title
          quiz_question_count
          show_answer_validation
          quiz_question_quiz_decks {
            default_title
            quiz_question_data {
              __typename
              ...on QuizMatchingQuestion {
                question_text
                pairs
                id
              }
              ...on QuizTextQuestion {
                question_text
                valid_text_responses
                id
              }
              ...on QuizOptionQuestion {
                question_text
                options
                id
              }
            }
          }
        }
        app_user {
          name
          email
        }
        quiz_responses {
          quiz_question_id
          quiz_question_type
          is_correct
          correct_answer
          value
          created_at
        }
        quiz_question_responses_count
      }
      count
      }
    }
  `
}

export const addChannel = gql`
  mutation createAppContentScope(
    $name: String!
    $public: Boolean
    $published: Boolean
    $position: Int
    $logo: Upload
    $app_content_scope_group_id: ID
    $organization_id: ID
    $description: String
    $pincode: String
    $content_controlled: Boolean
    $media_url: String
    $abbr: String
    $sales_button_enabled: Boolean
    $sales_incentive_button_title: String
    $sales_incentive_description: String
    $sales_incentive_title: String
    $sales_incentive_image: String
    $sales_incentive_image_upload: Upload
    $is_requires_subscription: Boolean
    $is_travel_pass_channel: Boolean
    $has_data_source_connected: Boolean
    $subscription_into_headline: String
    $subscription_description: String
    $subscription_button_text: String
    $subscription_image: Upload
    $subscription_id: ID
    $subscription_ids: String 
    $icon: Upload
    $icon_data: String
    $can_share_channel_data: Boolean
    $is_file_explorer: Boolean
    $file_explorer_root_path: String
    $file_explorer_default_view: String
    $is_firebase_channel: Boolean
    $default_template_group_ids: [ID]
    $is_primary_channel: Boolean
    $is_modal_presentation: Boolean
    $hide_from_navigation: Boolean
    $display_modal_on_no_sub: Boolean
    $display_modal_on_no_acct: Boolean
    $hide_from_navigation_list_on_modal_check: Boolean
    $modal_presentation_style: String
    $display_modal_on_sub_no_acct: Boolean
    $display_modal_link_acct: Boolean
    $display_modal_link_user_meta_data: Boolean
    $is_multimedia_channel: Boolean
    $is_analytics_report_template: Boolean
    $is_map_channel: Boolean
  ) {
    createAppContentScope(
      name: $name
      public: $public
      published: $published
      position: $position
      logo: $logo
      app_content_scope_group_id: $app_content_scope_group_id
      organization_id: $organization_id
      description: $description
      pincode: $pincode
      content_controlled: $content_controlled
      media_url: $media_url
      abbr: $abbr
      sales_button_enabled: $sales_button_enabled
      sales_incentive_button_title: $sales_incentive_button_title
      sales_incentive_description: $sales_incentive_description
      sales_incentive_title: $sales_incentive_title
      sales_incentive_image: $sales_incentive_image
      sales_incentive_image_upload: $sales_incentive_image_upload
      is_requires_subscription: $is_requires_subscription
      is_travel_pass_channel: $is_travel_pass_channel
      has_data_source_connected: $has_data_source_connected
      subscription_into_headline: $subscription_into_headline
      subscription_description: $subscription_description
      subscription_button_text: $subscription_button_text
      subscription_image: $subscription_image
      subscription_id: $subscription_id
      subscription_ids: $subscription_ids
      icon: $icon
      icon_data: $icon_data
      can_share_channel_data: $can_share_channel_data
      default_template_group_ids: $default_template_group_ids
      is_file_explorer: $is_file_explorer
      file_explorer_root_path: $file_explorer_root_path
      file_explorer_default_view: $file_explorer_default_view
      is_firebase_channel: $is_firebase_channel
      is_primary_channel: $is_primary_channel
      hide_from_navigation_list_on_modal_check: $hide_from_navigation_list_on_modal_check
      display_modal_on_no_acct: $display_modal_on_no_acct
      is_modal_presentation: $is_modal_presentation
      hide_from_navigation: $hide_from_navigation
      display_modal_on_no_sub: $display_modal_on_no_sub
      modal_presentation_style: $modal_presentation_style
      display_modal_on_sub_no_acct: $display_modal_on_sub_no_acct
      display_modal_link_acct: $display_modal_link_acct
      display_modal_link_user_meta_data: $display_modal_link_user_meta_data
      is_multimedia_channel: $is_multimedia_channel
      is_analytics_report_template: $is_analytics_report_template
      is_map_channel: $is_map_channel
    ) {
      id
      name
      public
      published
      is_requires_subscription
      subscription_into_headline
      subscription_description
      subscription_button_text
      subscription_image_data
      subscription_id
      subscription {
        name
        id
      }
      request_id
    }
  }
`;

export const moveCardMutation = gql`
  mutation moveCard($id: ID!, $deck_id: ID!) {
    moveCard(id: $id, deck_id: $deck_id) {
      title
    }
  }
`;

export const updateProfileMutation = gql`
  mutation updateProfile($userProfileInput: UserProfile) {
    updateProfile(userProfileInput: $userProfileInput)
  }
`;

export const cloneCardMutation = gql`
  mutation cloneCard($id: ID!, $deck_id: ID!) {
    cloneCard(id: $id, deck_id: $deck_id) {
      title
    }
  }
`;

export const archiveCardMutation = gql`
  mutation archiveCard($id: ID!, $deck_id: ID!) {
    archiveCard(id: $id, deck_id: $deck_id) {
      title
    }
  }
`;

export const unArchiveCardMutation = gql`
  mutation unArchiveCard($id: ID!, $deck_id: ID!) {
    unArchiveCard(id: $id, deck_id: $deck_id) {
      title
    }
  }
`;

export const archiveQuizCardMutation = gql`
  mutation archiveQuizCard($id: ID, $quiz_deck_id: ID) {
    archiveQuizCard(id:$id, quiz_deck_id: $quiz_deck_id)
  }
`;

export const unArchiveQuizCardMutation = gql`
  mutation unArchiveQuizCard($id: ID, $quiz_deck_id: ID) {
    unArchiveQuizCard(id:$id, quiz_deck_id: $quiz_deck_id)
  }
`;

export const archiveCardStackCardMutation = gql`
  mutation archiveCardStackCard($id: ID!, $card_stack_id: ID!) {
    archiveCardStackCard(id:$id, card_stack_id: $card_stack_id) {
      title
    }
  }
`;

export const unArchiveCardStackCardMutation = gql`
  mutation unArchiveCardStackCard($id: ID!, $card_stack_id: ID!) {
    unArchiveCardStackCard(id:$id, card_stack_id: $card_stack_id) {
      title
    }
  }
`;


export const updateChannel = gql`
  mutation updateAppContentScope(
    $id: ID!
    $name: String
    $public: Boolean
    $logo: Upload
    $position: Int
    $description: String
    $pincode: String
    $content_controlled: Boolean
    $media_url: String
    $abbr: String
    $logo_data: String
    $sales_button_enabled: Boolean
    $sales_incentive_button_title: String
    $sales_incentive_description: String
    $sales_incentive_title: String
    $sales_incentive_image: String
    $sales_incentive_image_upload: Upload
    $archived: Boolean
    $published: Boolean
    $is_requires_subscription: Boolean
    $is_travel_pass_channel: Boolean
    $has_data_source_connected: Boolean
    $subscription_into_headline: String
    $subscription_description: String
    $subscription_button_text: String
    $subscription_image: Upload
    $subscription_id: ID
    $subscription_ids: String
    $icon: Upload
    $icon_data: String
    $tags: [ChannelTagInput]
    $can_share_channel_data: Boolean
    $is_file_explorer: Boolean
    $file_explorer_root_path: String
    $file_explorer_default_view: String
    $is_firebase_channel: Boolean
    $is_primary_channel: Boolean
    $hide_from_navigation_list_on_modal_check: Boolean
    $display_modal_on_no_acct: Boolean
    $display_modal_on_no_sub: Boolean
    $is_modal_presentation: Boolean
    $hide_from_navigation: Boolean
    $modal_presentation_style: String
    $display_modal_on_sub_no_acct: Boolean
    $display_modal_link_acct: Boolean
    $display_modal_link_user_meta_data: Boolean
    $is_multimedia_channel: Boolean
    $is_analytics_report_template: Boolean
    $is_map_channel: Boolean
  ) {
    updateAppContentScope(
      id: $id
      name: $name
      public: $public
      logo: $logo
      position: $position
      description: $description
      pincode: $pincode
      content_controlled: $content_controlled
      media_url: $media_url
      abbr: $abbr
      logo_data: $logo_data
      sales_button_enabled: $sales_button_enabled
      sales_incentive_button_title: $sales_incentive_button_title
      sales_incentive_description: $sales_incentive_description
      sales_incentive_title: $sales_incentive_title
      sales_incentive_image: $sales_incentive_image
      sales_incentive_image_upload: $sales_incentive_image_upload
      archived: $archived
      published: $published
      is_requires_subscription: $is_requires_subscription
      is_travel_pass_channel: $is_travel_pass_channel
      has_data_source_connected: $has_data_source_connected
      subscription_into_headline: $subscription_into_headline
      subscription_description: $subscription_description
      subscription_button_text: $subscription_button_text
      subscription_image: $subscription_image
      subscription_id: $subscription_id
      subscription_ids: $subscription_ids
      icon: $icon
      icon_data: $icon_data
      tags: $tags
      can_share_channel_data: $can_share_channel_data
      is_file_explorer: $is_file_explorer
      file_explorer_root_path: $file_explorer_root_path
      file_explorer_default_view: $file_explorer_default_view
      is_firebase_channel: $is_firebase_channel
      is_primary_channel: $is_primary_channel
      hide_from_navigation_list_on_modal_check: $hide_from_navigation_list_on_modal_check
      display_modal_on_no_acct: $display_modal_on_no_acct
      display_modal_on_no_sub: $display_modal_on_no_sub
      is_modal_presentation: $is_modal_presentation
      hide_from_navigation: $hide_from_navigation
      modal_presentation_style: $modal_presentation_style
      display_modal_on_sub_no_acct: $display_modal_on_sub_no_acct
      display_modal_link_acct: $display_modal_link_acct
      display_modal_link_user_meta_data: $display_modal_link_user_meta_data
      is_multimedia_channel: $is_multimedia_channel
      is_map_channel: $is_map_channel
      is_analytics_report_template: $is_analytics_report_template
    ) {
      name
      public
      position
      content_controlled
      archived
      published
      is_requires_subscription
      subscription_into_headline
      subscription_description
      subscription_button_text
      subscription_image_data
      subscription_id
      file_explorer_default_view
      display_modal_link_user_meta_data
      is_multimedia_channel
      is_map_channel
      subscription {
        name
        id
      }
    }
  }
`;

export const removeChannel = gql`
  mutation deleteAppContentScope(
    $id: ID!
  ) {
    deleteAppContentScope(
      id: $id
    ) {
      name
    }
  }
`;

export const addGroup = gql`
  mutation createAppContentScopeGroup(
    $name: String!
    $position: Int
    $organization_id: ID
    $tags: [ID]
  ) {
    createAppContentScopeGroup(
      name: $name
      position: $position
      organization_id: $organization_id
      tags: $tags
    ) {
      name
      organization_id
      position
      id
    }
  }
`;

export const updateGroup = gql`
  mutation updateAppContentScopeGroup($id: ID!, $name: String!, $position: Int, 
    $public: Boolean
    $primary_color: String,
    $secondary_color: String,
    $deck_cover_radius_top_left: Int
    $deck_cover_radius_top_right: Int
    $deck_cover_radius_bottom_left: Int
    $deck_cover_radius_bottom_right: Int
    $deck_shadow_radius_top_left: Int
    $deck_shadow_radius_top_right: Int
    $deck_shadow_radius_bottom_left: Int
    $deck_shadow_radius_bottom_right: Int
    $group_navigation_level: String
    $is_files_magic_group: Boolean
    $archived: Boolean
    $tags: [ID]
) {
    updateAppContentScopeGroup(id: $id, name: $name, position: $position, public: $public
      primary_color: $primary_color,
      secondary_color: $secondary_color,
      deck_cover_radius_top_left: $deck_cover_radius_top_left
      deck_cover_radius_top_right: $deck_cover_radius_top_right
      deck_cover_radius_bottom_left: $deck_cover_radius_bottom_left
      deck_cover_radius_bottom_right: $deck_cover_radius_bottom_right
      deck_shadow_radius_top_left: $deck_shadow_radius_top_left
      deck_shadow_radius_top_right: $deck_shadow_radius_top_right
      deck_shadow_radius_bottom_left: $deck_shadow_radius_bottom_left
      deck_shadow_radius_bottom_right: $deck_shadow_radius_bottom_right
      group_navigation_level: $group_navigation_level
      is_files_magic_group: $is_files_magic_group
      archived: $archived
      tags: $tags
      ) {
      name
      organization_id
      position
      public
      id
    }
  }
`;

export const deleteGroup = gql`
  mutation deleteAppContentScopeGroup($id: ID!) {
    deleteAppContentScopeGroup(id: $id) {
      name
      organization_id
      position
      id
    }
  }
`;

export const addCategoryMutation = gql`
  mutation createCategory(
    $name: String!
    $published: Boolean!
    $app_content_scope_id: String!
    $position: Int!
    $created_by_id: ID!
    $organization_id: ID!
    $public: Boolean!
    $description: String!
    $archived: Boolean!
    $layout: String
    $deck_style: String
  ) {
    createCategory(
      name: $name
      published: $published
      app_content_scope_id: $app_content_scope_id
      position: $position
      created_by_id: $created_by_id
      organization_id: $organization_id
      public: $public
      description: $description
      archived: $archived
      layout: $layout
      deck_style: $deck_style
    ) {
      id
      name
      layout
      deck_style
    }
  }
`;
export const addCategoryWithoutSubScriptionMutation = gql`
  mutation createCategoryWithoutSubscription(
    $name: String!
    $published: Boolean!
    $app_content_scope_id: String!
    $position: Int!
    $created_by_id: ID!
    $organization_id: ID!
    $public: Boolean!
    $description: String!
    $archived: Boolean!
    $layout: String
    $deck_style: String
  ) {
    createCategoryWithoutSubscription(
      name: $name
      published: $published
      app_content_scope_id: $app_content_scope_id
      position: $position
      created_by_id: $created_by_id
      organization_id: $organization_id
      public: $public
      description: $description
      archived: $archived
      layout: $layout
      deck_style: $deck_style
    ) {
      id
      name
      layout
      deck_style
    }
  }
`;

export const updateCategoryMutation = gql`
  mutation updateCategory(
    $id: ID!
    $name: String
    $published: Boolean
    $description: String
    $public: Boolean
    $position: Int
    $layout: String
    $has_data_source_connected: Boolean
    $api_path_id: ID
    $deck_style: String
    $tags: [CategoryTagInput]
    $app_content_scope_id: ID
  ) {
    updateCategory(
      id: $id
      name: $name
      published: $published
      description: $description
      public: $public
      position: $position
      layout: $layout
      has_data_source_connected: $has_data_source_connected
      api_path_id: $api_path_id
      deck_style: $deck_style
      tags: $tags
      app_content_scope_id: $app_content_scope_id
    ) {
      name
      description
      position
      public
      layout
      has_data_source_connected
      api_path_id
      deck_style
    }
  }
`;

export const updateComponentMutation = gql`
mutation updateHomeComponent(
  $id: ID!,
  $title: String,
  $position: Int,
  $public: Boolean,
  $archived: Boolean,
  $published: Boolean
) {
  updateHomeComponent(
    id: $id,
    title: $title,
    position: $position,
    public: $public,
    archived: $archived,
    published: $published
  ) {
    title
  }
}
`;

export const archiveCategoryMutation = gql`
  mutation archiveCategory(
    $id: ID!
    $status: Boolean
  ) {
    archiveCategory(
      id: $id
      status: $status
    ){
      name
      published
      position
      deck_count
      id
      description
      public
      layout
    }
  }
`;

export const addDeckMutation = gql`
  mutation createDeck(
    $title: String!
    $description: String!
    $cover_image: Upload
    $color: String!
    $archived: Boolean!
    $organization_color_palette_index: Int!
    $icon_name: String
    $cover_type: String!
    $created_by_id: ID!
    $created_by_name: String
    $full_bleed: Boolean!
    $landscape: Boolean!
    $home_featured: Boolean!
    $home_featured_image_data: String
    $position: Int
    $published: Boolean
    $organization_id: ID!
    $category_id: String
    $card_style: String
    $use_card_template: Boolean
    $card_template: String
    $home_component_id: ID
  ) {
    createDeck(
      title: $title
      description: $description
      cover_image: $cover_image
      color: $color
      archived: $archived
      organization_color_palette_index: $organization_color_palette_index
      icon_name: $icon_name
      cover_type: $cover_type
      created_by_id: $created_by_id
      created_by_name: $created_by_name
      full_bleed: $full_bleed
      landscape: $landscape
      home_featured: $home_featured
      home_featured_image_data: $home_featured_image_data
      position: $position
      published: $published
      organization_id: $organization_id
      category_id: $category_id
      card_style: $card_style
      use_card_template: $use_card_template
      card_template: $card_template
      home_component_id: $home_component_id
    ) {
      id
      title
    }
  }
`;

export const addQuizDeckMutation = gql`
  mutation addQuizDeck(
    $title: String
    $description: String
    $organization_color_palette_index: Int
    $cover_type: String
    $created_by_id: ID
    $position: Int
    $category_id: String
    $organization_id: ID
  ) {
    addQuizDeck(
      title: $title
      description: $description
      organization_color_palette_index: $organization_color_palette_index
      cover_type: $cover_type
      created_by_id: $created_by_id
      position: $position
      category_id: $category_id
      organization_id: $organization_id
    ) {
      title
    }
  }
`;

export const updateQuizDeckMutation = gql`
mutation updateQuizDeck(
  $id: ID
  $cover_image: Upload
  $title: String
  $description: String
  $full_bleed: Boolean
  $organization_color_palette_index: Int
  $cover_image_data: String
  $cover_type: String
  $icon_name: String
  $show_answer_validation: Boolean
) {
  updateQuizDeck(
    id: $id
    cover_image: $cover_image
    title: $title
    description: $description
    full_bleed: $full_bleed
    organization_color_palette_index: $organization_color_palette_index
    cover_image_data: $cover_image_data
    cover_type: $cover_type
    icon_name: $icon_name
    show_answer_validation: $show_answer_validation
  ) {
    title
  }
}
`

export const cloneQuizDeckMutation = gql`
  mutation cloneQuizDeck(
    $id: ID
    $cover_image: Upload
    $title: String
    $description: String
    $full_bleed: Boolean
    $organization_color_palette_index: Int
    $cover_image_data: String
    $cover_type: String
    $icon_name: String
    $category_id: String
  ) {
    cloneQuizDeck(
      id: $id
      cover_image: $cover_image
      title: $title
      description: $description
      full_bleed: $full_bleed
      organization_color_palette_index: $organization_color_palette_index
      cover_image_data: $cover_image_data
      cover_type: $cover_type
      icon_name: $icon_name
      category_id: $category_id
    ) {
      title
    }
  }
`

export const updateDeckMutation = gql`
  mutation updateDeck(
    $id: ID!
    $title: String
    $description: String
    $full_bleed: Boolean
    $home_featured: Boolean
    $organization_color_palette_index: Int
    $cover_image: Upload
    $position: Int
    $category_id: ID
    $cover_image_data: String
    $cover_type: String
    $icon_name: String
    $has_data_source_connected: Boolean
    $api_path_id: ID
    $card_style: String
    $use_card_template: Boolean
    $card_template: String
  ) {
    updateDeck(
      id: $id
      title: $title
      description: $description
      full_bleed: $full_bleed
      home_featured: $home_featured
      organization_color_palette_index: $organization_color_palette_index
      cover_image_data: $cover_image_data
      cover_image: $cover_image
      position: $position
      category_id: $category_id
      cover_type: $cover_type
      icon_name: $icon_name
      has_data_source_connected: $has_data_source_connected
      api_path_id: $api_path_id
      card_style: $card_style
      use_card_template: $use_card_template
      card_template: $card_template
    ) {
      title
      has_data_source_connected
      api_path_id
      card_style
    }
  }
`;

export const cloneDeckMutation = gql`
  mutation cloneDeck(
    $id: ID
    $title: String!
    $description: String
    $cover_image_data: String
    $color: String
    $archived: Boolean
    $organization_color_palette_index: Int
    $icon_name: String
    $cover_type: String
    $created_by_id: ID
    $created_by_name: String
    $full_bleed: Boolean
    $landscape: Boolean
    $home_featured: Boolean
    $home_featured_image_data: String
    $position: Int
    $type: String
    $published: Boolean
    $organization_id: ID
    $category_id: String
    $card_style: String
    $use_card_template: Boolean
    $card_template: String
  ) {
    cloneDeck(
      id: $id
      title: $title
      description: $description
      cover_image_data: $cover_image_data
      color: $color
      archived: $archived
      organization_color_palette_index: $organization_color_palette_index
      icon_name: $icon_name
      cover_type: $cover_type
      created_by_id: $created_by_id
      created_by_name: $created_by_name
      full_bleed: $full_bleed
      landscape: $landscape
      home_featured: $home_featured
      home_featured_image_data: $home_featured_image_data
      position: $position
      published: $published
      organization_id: $organization_id
      category_id: $category_id
      card_style: $card_style
      use_card_template: $use_card_template
      card_template: $card_template
    ) {
      title
    }
  }
`;

export const moveDeckMutation = gql`
  mutation moveDeck($id: ID!, $category_id: ID!) {
    moveDeck(id: $id, category_id: $category_id) {
      title
    }
  }
`;

export const movedSlideMutation = gql`
  mutation moveSlide($id: ID!, $home_component_id: ID!) {
    moveSlide(id: $id, home_component_id: $home_component_id) {
      title
    }
  }
`;

export const archiveDeckMutation = gql`
  mutation archiveDeck($id: ID!) {
    archiveDeck(id: $id) {
      title
    }
  }
`;
export const archiveSlideMutation = gql`
  mutation archiveSlide($id: ID!) {
    archiveSlide(id: $id) {
      title
    }
  }
`;
export const unArchiveSlideMutation = gql`
  mutation unArchiveSlide($id: ID!) {
    unArchiveSlide(id: $id) {
      title
    }
  }
`;

export const unArchiveDeckMutation = gql`
  mutation unArchiveDeck($id: ID!) {
    unArchiveDeck(id: $id) {
      title
    }
  }
`;

export const moveCardStackCardMutation = gql`
  mutation moveCardStackCard($id: ID!, $home_component_id: ID!) {
    moveCardStackCard(id: $id, home_component_id: $home_component_id) {
      title
    }
  }
`;


export const addCardMutation = gql`
  mutation createCard(
    $title: String!
    $sub_title: String
    $card_type: String!
    $front_image: Upload
    $link: String
    $back_image_data: String
    $back_description: String
    $youtube_video_id: String
    $file_data: String
    $archived: Boolean
    $full_bleed: Boolean
    $organization_id: ID
    $created_by_id: ID
    $has_feedback: Boolean
    $card_favorite_count: Int
    $card_feedback_count: Int
    $created_by_name: String
    $deck_id: ID
    $position: Int
    $published: Boolean
  ) {
    createCard(
      title: $title
      sub_title: $sub_title
      card_type: $card_type
      front_image: $front_image
      link: $link
      back_image_data: $back_image_data
      back_description: $back_description
      youtube_video_id: $youtube_video_id
      file_data: $file_data
      archived: $archived
      full_bleed: $full_bleed
      organization_id: $organization_id
      created_by_id: $created_by_id
      has_feedback: $has_feedback
      card_favorite_count: $card_favorite_count
      card_feedback_count: $card_feedback_count
      created_by_name: $created_by_name
      deck_id: $deck_id
      position: $position
      published: $published
    ) {
      id
      title
    }
  }
`;

export const getIntakeData = (organization_id, page) => {
  return gql`
    query intake{
      intake_forms_org_paginated(organization_id: "${organization_id}", page:${page}){
        rows {
          from_name
          sales_rep_first_name
          sales_rep_last_name
          invoice_number
          invoice_photo
          modal_name
          product_serial_number
          sales_date
          posted_by_user {
            id
            name
            email
          }
        }
        count
      }
    }
  `;
};


export const getSpecification = (organization_id) => gql`
  query specifications_org {
    specifications_org(
      organization_id: "${organization_id}"
      ) {
        id
        name
        specification_values{
          name 
          id
        }
    }
  }
`;

export const getTags = (organization_id) => gql`
  query tags {
    tags_org(
      organization_id: "${organization_id}"
      ) {
        id
        name
        cover_image
        position
        metadata
        type
        marker_icon_url
    }
  }
`;

export const getTagsAndCategory = (organization_id, category_id) => gql`
  query tags {
    tags_org(
      organization_id: "${organization_id}"
      ) {
        id
        name
        cover_image
        position
        metadata
        type
    }
    category(id: "${category_id}") {
      category_tags {
        tag {
          id
          name
        }
      }
    }
  }
`;

export const getTagsAndChannel = (organization_id, channel_id) => {
  return gql`
  query tags {
    tags_org(
      organization_id: "${organization_id}"
      ) {
        id
        name
        cover_image
        position
        metadata
        type
    }
    app_content_scope(id: "${channel_id}") {
      tags {
        tag {
          id
          name
        }
      }
    }
  }
  `;
}

export const createTag = gql`
  mutation createTag ($name: String, $organization_id: ID!, $position: Int, $cover_image: Upload, $type: String, $marker_icon_url: String, $metadata: String) {
    createTag(name: $name, position: $position, organization_id: $organization_id, cover_image: $cover_image, type: $type, marker_icon_url: $marker_icon_url, metadata: $metadata)
   {
        name
        organization_id
        cover_image
        position
        marker_icon_url
        metadata
    }
  }
`;

export const updateTag = gql`
  mutation updateTag ($id: ID!, $name: String, $position: Int, $isDeleted: Boolean, $cover_image: Upload, $metadata: String, $type: String, $marker_icon_url: String) {
    updateTag(id: $id, name: $name, position: $position, isDeleted: $isDeleted, cover_image: $cover_image, metadata: $metadata, type: $type, marker_icon_url: $marker_icon_url)
   {
        id
        name
        cover_image
        position
        metadata
        marker_icon_url
    }
  }
`;

export const getCardTags = (card_id) => gql`
  query card_tag_by_card {
    card_tag_by_card(
      card_id: "${card_id}"
      ) {
        id
        tag_id
    }
  }
`;

export const getUserTags = (app_user_id) => gql`
  query user_tag_by_user {
    user_tag_by_user(
      app_user_id: "${app_user_id}"
      ) {
        id
        tag_id
    }
  }
`;


export const updateCardTag = gql`
mutation updateCardTag($input: CardTagUpdateInput) {
  updateCardTag(input: $input) {
    id
    tag_id
  }
}
`;


export const createSpecification = gql`
mutation createSpecification($name: String, $organization_id: ID) {
  createSpecification(name: $name, organization_id: $organization_id) {
    name
    id
  }
}
`;

export const updateSpecification = gql`
mutation updateSpecification($id: ID!, $name: String, $isDeleted: Boolean) {
  updateSpecification(id: $id, name: $name, isDeleted: $isDeleted) {
    name
  }
}
`;

export const createSpecificationValue = gql`
mutation createSpecificationValue(
  $name: String!
  $organization_id: ID!
  $specification_id: ID!
) {
  createSpecificationValue(
    name: $name
    specification_id: $specification_id
    organization_id: $organization_id
  ) {
    name
  }
}
`;

export const updateSpecificationValue = gql`
mutation updateSpecificationValue(
  $id: ID!
  $name: String
  $isDeleted: Boolean
) {
  updateSpecificationValue(id: $id, name: $name, isDeleted: $isDeleted) {
    name
    id
  }
}
`;


export const createCardURLParams = gql`
  mutation createCardURLParams($organization_id: ID!, $card_id: ID!, $key_id: ID, $value_id: ID, $has_custom_value: Boolean, $custom_value: String) {
    createCardURLParams(organization_id: $organization_id, card_id: $card_id, key_id: $key_id, value_id: $value_id, has_custom_value: $has_custom_value, custom_value: $custom_value) {
      id
    }
  }
`

export const updateCardURLParams = gql`
  mutation updateCardURLParams($id: ID!, $key_id: ID, $value_id: ID, $has_custom_value: Boolean, $custom_value: String) {
    updateCardURLParams(id: $id, key_id: $key_id, value_id: $value_id, has_custom_value: $has_custom_value, custom_value: $custom_value) {
      id
    }
  }
`

export const createCardVisibilityParams = gql`
  mutation createCardVisibilityParams($organization_id: ID!, $card_id: ID!, $key_id: ID, $value_id: ID, $has_custom_value: Boolean, $custom_value: String) {
    createCardVisibilityParams(organization_id: $organization_id, card_id: $card_id, key_id: $key_id, value_id: $value_id, has_custom_value: $has_custom_value, custom_value: $custom_value) {
      id
    }
  }
`;

export const updateCardVisibilityParams = gql`
  mutation updateCardVisibilityParams($id: ID!, $key_id: ID, $value_id: ID, $has_custom_value: Boolean, $custom_value: String) {
    updateCardVisibilityParams(id: $id, key_id: $key_id, value_id: $value_id, has_custom_value: $has_custom_value, custom_value: $custom_value) {
      id
    }
  }
`;

export const getAdminPageSliders = gql`
  query adminIndexPageSliders {
    adminIndexPageSliders{
      id
      image
      title
      description
      button_text
      button_url
      background_color
    }
  }
`;


export const home_components_query = (id, CardBuilderOptions) => gql`
query home_components {
    home_component_app_content_scopes_org(app_content_scope_id: "${id}") {
      position
      published
      id
      home_component{
        id
        title
        position
        archived
        public
        published
        component_type
        has_data_source_connected
        api_path_id
        is_single_form
        is_stepped_form
        form_module_intro_heading
        disable_filter_button
        form_module_intro_description
        form_module_intro_cover_image
        form_module_intro_button_text
        form_module_step_button_text
        form_module_last_step_button_text
        form_module_scoring_script
        form_module_is_first_form_meta
        form_report_type
        style_override
        decks {
          category_id
          home_component_id
          deck_id
        }
        card_stack_home_components{
          published
          position
          id
          card_back_home_component_card {
            title
            sub_title
            updated_at
            file_data
            front_image_data
            full_bleed
            id
            archived
            card_type
            link

            visible_time
            source_start_day 
            source_end_day 
            source_type
            other_type 
            source_date
            custom_field_id
            custom_field {
              integration_type
              field_name
            }
            card_tags {
              id
              tag {
                name
              }
            }
            card_decks {
              deck_id
              published
            }

            has_data_source_connected
            api_path_id
          }
        }
        # copy_card_home_components{
        #   published
        #   position
        #   id
        #   card_back_home_component_card {
        #     title
        #     sub_title
        #     updated_at
        #     file_data
        #     front_image_data
        #     full_bleed
        #     id
        #     archived
        #     card_type
        #     link

        #     visible_time
        #     source_start_day 
        #     source_end_day 
        #     source_type
        #     other_type 
        #     source_date
        #     custom_field_id
        #     custom_field {
        #       integration_type
        #       field_name
        #     }

        #     card_decks {
        #       deck_id
        #       published
        #     }

        #     has_data_source_connected
        #     api_path_id
        #   }
        # }
        slide_home_components{
          published
          position
          id
          slide_home_component_slide{
            id
            title
            link_type
            position
            published
            image_data
            link_asset
            archived
            redirect_card_id
            style_override
          }
        }
        card_content_modules {
          id
          content_module_type
          position
          published
          archived
          home_component_id
          content_module {
            ${CardBuilderOptions.filter(el => el.addInHomeComponent).map(el => {
  return `
                ...on ${el.__typename} {
                  id
                  ${el.inputs.map(el => {
    return `
            ${el.key} ${el.subQueryKeys
        ? `{
              ${el.subQueryKeys.map(el2 => el2 + '\n')}
            }`
        : ''
      }
          `
  })}
                }
              \n`;
})}
          }
        }
      }
    }
}
`;

export const getMicrosoftSheetAccessTokenByUsernamePassword = (client_id, client_secret, tenant_id, username, password) => gql`
  query get_microsoft_access_token_username_password {
    get_microsoft_access_token_username_password(
        client_id: "${client_id}"
        client_secret: "${client_secret}"
        tenant_id: "${tenant_id}"
        username: "${username}"
        password: "${password}"
      ) {
        tokenType
        accessToken
    }
  }
`;


export const addCardStackCard = gql`
  mutation addCardStackCard(
    $input: createCardStackCardInput
  ) {
    addCardStackCard(
      input: $input
    ) {
      card {
        id
        title
      }
    }
  }
`;

export const addCopyCard = gql`
  mutation addCopyCard(
    $input: createCopyCardInput
  ) {
    addCopyCard(
      input: $input
    ) {
      card {
        id
        title
      }
    }
  }
`;


export const importCopyCard = gql`
  mutation importCopyCard(
    $card_id: ID
    $deck_id: ID
    $card_stack_id: ID
    $position: Int
  ) {
    importCopyCard(
      card_id: $card_id
      deck_id: $deck_id
      card_stack_id: $card_stack_id
      position: $position
    ) {
      card_deck {
        id
      }
      card_stack_home_component {
        id
      }
    }
  }
`;

export const importChannelListFromCsvSubscription = gql`
  subscription importChannelListFromCsv($request_id: String) {
    importChannelListFromCsv(request_id: $request_id) {
          is_done
          is_error
          error
          result
          request_id
          total
          current
      }
  }
`;

export const importChannelListFromCsv = gql`
  mutation importChannelListFromCsv (
    $organization_id: ID
    $app_content_scope_group_id: ID
    $file: Upload
    $published: Boolean
  ) {
    importChannelListFromCsv(
      organization_id: $organization_id
      app_content_scope_group_id: $app_content_scope_group_id
      file: $file
      published: $published
    ) {
      status
      message
      request_id
      error
      is_completed
    }
  }
`;

export const app_users_form_responses = gql`
  query app_users_form_responses(
    $organization_id: ID
    $page: Int
    $pageSize: Int
  ) {
    app_users_form_responses(
      organization_id: $organization_id
      page: $page
      pageSize: $pageSize
    ) {
      data {
        id
        app_user_id
        home_component_id
        form_id
        position
        submitted_data
        is_pushed
        report_id
        category_app_content_scope {
          category {
            id
            name
          }

          app_content_scope {
            id
            name
          }
          deck {
            id
            title
          }
          card {
            id
            title
          }
        }
        home_component_app_content_scope {
          id
          home_component {
            id
            title
          }
          app_content_scope {
            id
            name
          }
        }

        home_component {
          id
          title
        }

        app_content_scope {
          id
          name
        }

        app_user {
          id
          first_name
          last_name
          email
        }
        created_at
        updated_at
      }
      count
    }
  }
`;

export const app_content_scope_users_submitted_responses = gql`
  query app_content_scope_users_submitted_responses (
    $organization_id: ID, $channel_id: ID, $page: Int, $pageSize: Int
  ) {
    app_content_scope_users_submitted_responses (
      organization_id: $organization_id, channel_id: $channel_id, page: $page, pageSize: $pageSize
    ) {
      data {
        id
        uid
        name
        first_name
        last_name
        email
        created_at
        forms {
          id
          title
          description
          updated_at
          categoryd_id
          home_component_id
          category_name
          home_component_name
          card_id
          deck_id
          card_name
          deck_name
          submittedResponses {
            id,
            created_at,
            submitted_data
          }
          is_submitted
        }
      }
      count
    }
  }
`;

export const app_content_scope_compliance_user_analytics = gql`
  query app_content_scope_compliance_user_analytics (
    $organization_id: ID, $channel_id: ID, $page: Int, $pageSize: Int, $user_filter: String
  ) {
    app_content_scope_compliance_user_analytics (
      organization_id: $organization_id, channel_id: $channel_id, page: $page, pageSize: $pageSize, user_filter: $user_filter
    ) {
      data {
        id
        uid
        name
        first_name
        last_name
        email
        created_at
        forms {
          id
          title
          description
          updated_at
          categoryd_id
          home_component_id
          category_name
          home_component_name
          card_id
          deck_id
          card_name
          deck_name
          submittedResponses {
            id,
            created_at,
            submitted_data
          }
          is_submitted
        }
      }
      count
      no_of_total_user
      no_of_compliant_user
      no_of_incompliant_user
    }
  }
`;

export const organization_app_content_scope_compliance_user_analytics = gql`
  query organization_app_content_scope_compliance_user_analytics (
    $organization_id: ID
    $page: Int
    $pageSize: Int
  ) {
    organization_app_content_scope_compliance_user_analytics(
      organization_id: $organization_id
      page: $page
      pageSize: $pageSize
    ) {
      data {
        id
        name
        no_of_total_user
        no_of_compliant_user
        no_of_incompliant_user
      }
      count
    }
  }
`;

export const moveCategoryToChannel = gql`
  mutation moveCategoryToChannel(
    $category_id: ID!
    $from_channel_id: ID!
    $to_channel_id: ID!
  ){
    moveCategoryToChannel(
      category_id: $category_id
      from_channel_id: $from_channel_id
      to_channel_id: $to_channel_id
    )
  }
`;

export const get_locations_by_orgs = gql`
  query get_locations_by_orgs($organization_id: ID!){
    get_locations_by_orgs(organization_id: $organization_id) {
      name
      id
    }
  }
`


export const filtered_cards = (organization_id) => gql`
query filtered_cards {
  filtered_cards(organization_id: "${organization_id}") {
    deck_cards {
      id
      card_title
      group_name
      channel_name
      category_name
      deck_title
      content_tracking_name
      content_tracking_id
      form_tracking_name
      form_tracking_id
      impression_tracking_name
      impression_tracking_id
    }
    stack_cards {
      id
      card_title
      home_component_title
      content_tracking_name
      content_tracking_id
      form_tracking_name
      form_tracking_id
      impression_tracking_name
      impression_tracking_id
    }
  }
}
`
export const exportChannelTemplate = gql`
mutation exportChannelTemplate(
  $organization_id: ID
  $channel_id: ID
  $name: String
) {
  exportChannelTemplate(
    organization_id: $organization_id
    channel_id: $channel_id
    name: $name
  ) 
}
`;

export const importChannelTemplate = gql`
mutation importChannelTemplate(
  $id: ID!
  $organization_id: ID
  $channel_id: ID
) {
  importChannelTemplate(
    id: $id
    channel_id: $channel_id
    organization_id: $organization_id
  )
}
`;

export const allChannelTemplateByOrg = gql`
query all_channel_template_by_org($organization_id: ID!) {
  all_channel_template_by_org(organization_id: $organization_id) {
    id
    name
    organization_id
  }
}
`;

